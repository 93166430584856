import httpClient from '../httpClient'

const ActivityLogService = {
  activityLog: function (obj: Record<string, any>) {
    $.ajax({
      type: 'POST',
      url: '/orders/log',
      beforeSend: $.rails.CSRFProtection,
      data: obj,
      success: () => {},
    })
  },

  createActivityLog: async function (operation: string, operationData: object): Promise<boolean> {
    const baseUrl = '/public_api/activity_logs'
    try {
      const response = await httpClient.post(`${baseUrl}/create_activity_log`, {
        operation: operation,
        operation_data: operationData,
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  trackSearchQuery: async function ({
    query,
    searchLocation,
  }: Record<string, string>): Promise<boolean> {
    const baseUrl = '/public_api/activity_logs'
    try {
      const response = await httpClient.post(`${baseUrl}/search_query`, {
        query,
        search_location: searchLocation,
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },
}

export default ActivityLogService
